import React from 'react';
import './marquee.css';

const Marquee = () => {
  const marqueeItems = [
    'Join us for the Abhishar Launch Ceremony at 20th November, 6:00 PM at the Auditorium!',
  ];

  return (
    <div className='marquee-heading'>
      <marquee behavior='scroll' direction='left' width='100%'>
        {marqueeItems.map((item, index) => (
          <span className='marquee-items' key={index}>
            {item}
          </span>
        ))}
      </marquee>
    </div>
  );
};

export default Marquee;
