import React from 'react';
import './ContactUsInnerBox.css';
import GetInTouch from '../../assets/svgIcons/getinTouch';
import ContactUsForm from '../../components/ContactUsForm/ContactUsForm';
import MailIcon from '../../assets/svgIcons/mailIcon';
import LocationIcon from '../../assets/svgIcons/locationIcon';
import CallIcon from '../../assets/svgIcons/callIcon';
const ContactUsInnerBox = () => {
  return (
    <div className='contact-us-page-innerbox'>
      <div className='Form-details'>
        <ContactUsForm />
      </div>
      <div className='Contact-details'>
        <div className='Contact-image'>
          <GetInTouch />
        </div>

        <div className='Contact-items'>
          <div className='Contact-details-item'>
            <div>
              <LocationIcon />
            </div>
            <div>E-116, ABV-IIITM Gwalior- 474015, Madhya Pradesh</div>
          </div>
          <div className='Contact-details-item'>
            <div>
              <MailIcon />
            </div>
            <div>aasf.iiitm@gmail.com, aasf@iiitm.ac.in</div>
          </div>
          <div className='Contact-details-item'>
            <div>
              <CallIcon />
            </div>
            <div>+91 63007 22698</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsInnerBox;
